<template>
  <div class="identification-page">
    <!-- 头部 -->
    <!-- <header>
      <van-icon class="backBtn" name="arrow-left" @click="$router.back()" />
      <div class="title">区块链标识</div>
    </header> -->
    <!-- 证书信息 -->
    <div class="headerBg">
      <span class="backIcon" @click="$router.back()"
        ><van-icon name="arrow-left"
      /></span>
      <p>#{{$t('blockchain_verify')}}#</p>
    </div>
    <div class="certificate">
      <div class="top">
        <img
          class="successImg"
          src="../assets/img/Identification/u11.png"
          alt=""
        />
        <div class="rightText">
          <h3>{{ $t('pass')  }}</h3>
          <p>{{ $t('pass_content') }}</p>
        </div>
      </div>
      <div class="bottom">
        <div class="background">
          <!-- logo -->
          <img
            class="hxLogo"
            src="../assets/img/Identification/u10.png"
            alt=""
          />
          <!-- 区块证书信息 -->
          <div class="hashDetail">
            <div class="hashContent">
              <span>{{$t('txhash')}}：</span>
              <br />
              <span class="colorText hashSpan">
                {{ data.txhash }}
              </span>
            </div>
            <div class="hashContent">
              <span>{{$t('signature')}}：</span>
              <br />
              <span class="colorText hashSpan">
                {{ data.hash }}
              </span>
            </div>
            <div class="itemContent">
              <span>{{ $t('evidence_time') }}：</span>
              <span class="colorText"> {{ data.blockTime | dateForMet }} </span>
            </div>
            <div class="itemContent">
              <span>{{$t('height')}}：</span>
              <span class="colorText"> {{ data.blockNumber }} </span>
            </div>
            <div class="itemContent">
              <span>{{ $t('blockchian') }}：</span>
              <span class="colorText"> {{ $t('hxchain') }} </span>
            </div>
          </div>

          <!-- 委任方 -->
          <div class="menberUnit">
            <img
              style="width: 100px"
              src="../assets/img/Identification/hx-logo.png"
              alt=""
            />
            <div class="gdInternet">
              <img src="../assets/img/Identification/blockLogo.jpg" alt="" />
            </div>
          </div>

          <!-- 印章 -->
          <div class="seal">
            <img src="../assets/img/Identification/seal.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateForMet } from "../utils/filters";
import Footer from "../components/footer/Footer.vue";
export default {
  data() {
    return {
      data: "",
    };
  },
  components: {
    Footer,
  },
  methods: {},
  mounted() {
    // 刷新页面重载时获取localStory储存里的数据，重新渲染
    const data = localStorage.getItem("data");
    if (data) {
      this.data = JSON.parse(data);
    } else {
      this.data = JSON.parse(this.$route.params.data);
      localStorage.setItem("data", this.$route.params.data);
    }
  },
  beforeDestroy() {
    // 退出当前页面，页面储存
    localStorage.removeItem("data");
  },
  filters: {
    dateForMet,
  },
};
</script>

<style lang="less" scoped>
.identification-page {
  max-width: 660px;
  // max-height: 800px;
  margin: 0 auto;
  background-color: #2c43e8;
  display: flex;
  flex-direction: column;
  height: 100vh;
  // background-color: #fbfbfb;
  // 头部
  header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 44px;
    line-height: 44px;
    background-color: #fff;
    z-index: 2;
    .backBtn {
      position: absolute;
      width: 24px;
      left: 20px;
      top: 14px;
    }
    .title {
      text-align: center;
    }
  }

  .headerBg {
    height: 100px;
    background-image: url("../assets/img/Identification/u3.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    font-weight: 700;
    color: #fff;
    position: relative;
    .backIcon {
      position: absolute;
      left: 15px;
      top: 15px;
      width: 25px;
      height: 25px;
      line-height: 25px;
      padding-left: 4px;
      display: inline-block;
      background-color: #fff;
      color: #2c43e8;
      font-size: 16px;
      border-radius: 50%;
      font-weight: 700;
      cursor: pointer;
    }
  }

  .certificate {
    background-color: #2c43e8;
    background-image: url("../assets/img/Identification/u1.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: calc(100% - 30px);
    padding: 0 15px;
    flex: 1;
    .top {
      height: 90px;
      background-color: #fff;
      border-radius: 5px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding: 0 15px;
      .successImg {
        width: 42px;
        height: 42px;
        margin-right: 10px;
      }
      .rightText {
        flex: 1;
        line-height: 25px;

        h3 {
          color: #148df5;
        }
        p {
          font-size: 14px;
        }
      }
    }
    .bottom {
      height: 510px;
      background-color: #fff;
      border-radius: 5px;
      margin-top: 10px;
      padding: 20px 10px;
      width: 100%;
      .background {
        position: relative;
        height: 100%;
        background-image: url("../assets/img/Identification/borderB.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .hxLogo {
          width: 100px;
          height: 75px;
          margin: 0 auto;
        }

        .hashDetail {
          .hashContent {
            margin-top: 8px;
            margin-bottom: 8px;
          }
          .itemContent {
            line-height: 25px;
          }
          span {
            font-size: 14px;
          }
          .colorText {
            color: #148df5;
          }
          .hashSpan {
            white-space: -moz-pre-wrap;
            word-wrap: break-word;
            white-space: -o-pre-wrap;
            white-space: -pre-wrap;
          }
        }

        .menberUnit {
          display: flex;
          flex-direction: column;
          align-items: center;
          .gdInternet {
            font-size: 12px;
            color: #98373d;
            margin-top: 5px;
            img {
              height: 30px;
              vertical-align: middle;
            }
          }
        }

        .seal {
          position: absolute;
          right: 30px;
          bottom: 100px;
          img {
            width: 100px;
            height: 100px;
          }
        }
      }
    }
  }
  // 底部
  .footer {
    position: absolute;
    bottom: 0px;
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
</style>
